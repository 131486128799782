import { useTrackPage } from '../../utils/analytics'

const BusinessNameGeneratorPage = () => {
  // TODO: We currently don't pass a page name in production either...
  useTrackPage('')

  return (
    <>
      <h1>Business Name Generator Fragment!</h1>
    </>
  )
}

export default BusinessNameGeneratorPage
