import { useLogger, useUserContext } from '@vp/ubik-context'
import type { Logger } from '@vp/ubik-logging'
import BusinessNameGeneratorPage from '../app/pages/BusinessNameGeneratorPage'
import { App } from '../app'
import { DefaultPageTrackingParams } from '../app/utils/analytics'
import type { Locale } from '../@types/locale'
import type { EnvironmentContextType } from '../app/utils/environmentContext'

export interface Props {
  envContext: EnvironmentContextType
}

const defaultPageTrackingParams: DefaultPageTrackingParams = {
  route: '/business-name-generator',
  name: 'Business Name Generator',
}

export const Fragment = ({ envContext }: Props) => {
  const logger: Logger = useLogger()
  const locale = useUserContext().locale.split('-')
    .map((part, index) => (index === 0 ? part.toLowerCase() : part.toUpperCase()))
    .join('-')

  logger.log('Rendering Business Name Generator fragment for the locale', locale)

  return (
    <App defaultPageTrackingParams={defaultPageTrackingParams} locale={locale as Locale} envContext={envContext}><BusinessNameGeneratorPage /></App>
  )
}
